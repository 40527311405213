const baseURL = process.env.REACT_APP_API_URL

// Profile
export const API_LOGIN = `${baseURL}/profile/login/`
export const API_TEST_TOKEN = `${baseURL}/profile/test-token/`
export const API_REFRESH_TOKEN = `${baseURL}/profile/refresh-token/`
export const API_CHANGE_PASSWORD = `${baseURL}/profile/change-password/`
export const API_INFO_PROFILE = `${baseURL}/profile/info-profile/`
export const API_MEMBRES = `${baseURL}/profile/membres/`
export const API_POSTS = `${baseURL}/profile/postes/`
export const API_VILLES = `${baseURL}/profile/villes/`

// Marques
export const API_MARQUES = `${baseURL}/marque/marques/`
export const API_MAGASINS = `${baseURL}/marque/magasins/`

// RH
export const API_CONGES = `${baseURL}/rh/conges/`
export const API_DOCUMENTS = `${baseURL}/rh/documents/`
export const API_RETARDS = `${baseURL}/rh/retards/`
export const API_ABSENCES = `${baseURL}/rh/absences/`
export const API_PLANNING = `${baseURL}/rh/planning-members/`
export const API_PLANNING_DETAILS = `${baseURL}/rh/planning-members-detail/`
export const API_CREATE_PLANNING = `${baseURL}/rh/planning-members/`
export const API_ADD_POINTAGE = `${baseURL}/rh/ajouter-pointage/`

// Achats
export const API_ACHATS = `${baseURL}/achat/membres-achats/`

// Notifications
export const API_NOTIFICATIONS = `${baseURL}/notification/messages-list/`
export const API_SEND_NOTIFICATION = `${baseURL}/notification/send-tokens-liste/`
export const API_TOKENS = `${baseURL}/notification/tokens-list/`

// Variables
export const API_VARIABLES = `${baseURL}/profile/get-variable/`

