import axios from "axios"
import { COOKIE_ACCESS_TOKEN } from "constants/cookies"
import { API_ABSENCES } from "constants/endpoints"
import { MembreSubType, Pagination } from "constants/types"
import { Cookies } from "react-cookie"

export type AbsencesRequest = {
  p?: number
  magasin?: number | ""
  personel?: number | ""
  date_start?: string | ""
  date_end?: string | ""
  statut?: "en_attente" | "non_justifie" | "justifie" | "annule" | ""
  type?: "demande" | "pointage"
}

export type AbsencesResponse = {
  id: number
  date_debut: Date
  date_fin: Date
  duree: number
  date_demande: Date
  statut: "en_attente" | "non_justifie" | "justifie" | "annule"
  commentaire: string
  created_at: Date
  justificatif: string
  membre: MembreSubType
  pointage_matin_heure_debut: string
  pointage_matin_heure_fin: string
  pointage_apresmidi_heure_debut: string
  pointage_apresmidi_heure_fin: string
}

export type AbsencesError = {
  detail: "Authentication credentials were not provided." | undefined
}

export default async function absences(params: AbsencesRequest = {}) {

  const cookies = new Cookies()

  const response = await axios({
    headers: { Authorization: "Bearer " + cookies.get(COOKIE_ACCESS_TOKEN) },
    method: "GET",
    url: API_ABSENCES,
    params: {
      si_paginated: "True",
      ...params
    }
  })

  return response.data as Pagination<AbsencesResponse>
}
