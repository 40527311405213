import { useQueryClient, useMutation } from "@tanstack/react-query"
import addPointage, { AddPointageRequest } from "api/assiduite/addPointage"
import { KEY_POINTAGE } from "constants/queryKeys"
import toast from "react-hot-toast"

export default function useAddPointageMutation() {

  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: AddPointageRequest) => addPointage(data),
    onMutate: () => {
      toast.loading("Création en cours..", {
        id: "addPointage",
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [KEY_POINTAGE] })
      toast.success("Pointage crée avec succès", {
        id: "addPointage",
      })
    },
    onError: () => {
      toast.error("Une erreur est survenue", {
        id: "addPointage",
      })
    },
  })
}
