import axios from "axios"
import { COOKIE_ACCESS_TOKEN } from "constants/cookies"
import { API_RETARDS } from "constants/endpoints"
import { MembreSubType, Pagination } from "constants/types"
import { Cookies } from "react-cookie"

export type RetardsRequest = {
  p?: number
  magasin?: number | ""
  personel?: number | ""
  date_start?: string | ""
  date_end?: string | ""
}

export type RetardsResponse = {
  id: number
  heure_arrivee: Date
  duree: number
  statut: number
  commentaire: string
  created_at: Date
  membre: MembreSubType
  pointage_matin_heure_debut: string
  pointage_matin_heure_fin: string
  pointage_apresmidi_heure_debut: string
  pointage_apresmidi_heure_fin: string
}

export type RetardsError = {
  detail: "Authentication credentials were not provided." | undefined
}

export default async function retards(params: RetardsRequest = {}) {

  const cookies = new Cookies()

  const response = await axios({
    headers: { Authorization: "Bearer " + cookies.get(COOKIE_ACCESS_TOKEN) },
    method: "GET",
    url: API_RETARDS,
    params: {
      si_paginated: "True",
      ...params
    }
  })

  return response.data as Pagination<RetardsResponse>
}
