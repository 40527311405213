import axios from "axios"
import { COOKIE_ACCESS_TOKEN } from "constants/cookies"
import { API_ADD_POINTAGE } from "constants/endpoints"
import { Cookies } from "react-cookie"

export type AddPointageRequest = {
  employe?: number | ""
  checktime?: string | ""    
  checkdate?: string | ""
}

export type AddPointageResponse = {
  id: number
}

export type PointageError = {
  detail: "Authentication credentials were not provided." | undefined
}

function formatHeure(heure: string | ""): string {
  if (heure && /^\d{2}:\d{2}$/.test(heure)) {
    return `${heure}:00`;
  }
  return heure; 
}

export default async function addPointage(params: AddPointageRequest = {}) {

  const cookies = new Cookies()

  const formattedParams = {
    ...params,
    checktime: formatHeure(params.checktime || ""),
  };

  const response = await axios({
    headers: { Authorization: "Bearer " + cookies.get(COOKIE_ACCESS_TOKEN) },
    method: "POST",
    url: API_ADD_POINTAGE,
    data: formattedParams,
    
  })

  return response.data as AddPointageResponse
}
