import { Work } from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Autocomplete,
  TextField,
} from "@mui/material";
import MainFilter from "components/commun/MainFilter";
import MainPagination from "components/commun/MainPagination";
import Loader from "components/commun/ui/Loader";
import MFCard from "components/commun/ui/MFCard";
import MembrePopup from "components/personnels/components/MembrePopup";
import useRetardsQuery from "hooks/queries/assiduite/useRetardsQuery";
import useFilters from "hooks/useFilters";
import usePagination from "hooks/usePagination";
import {
  serverDateFormat,
  standardDateFormat,
  standardTimeFormat,
} from "utils/datetimeUtils";
import { useState } from "react";
import toast from "react-hot-toast";
import useAddPointageMutation from "hooks/queries/assiduite/useAddPointageMutation";
import useInfoProfileQuery from "hooks/queries/auth/useInfoProfileQuery";

export default function Retards() {
  const userInfoQuery = useInfoProfileQuery();
  const [activeDownloadID, setActiveDownloadID] = useState(undefined);
  const [error, setError] = useState({ heureArrivee: "" });
  const [heureArrivee, setHeureArrivee] = useState("");

  const addPointage = useAddPointageMutation();

  const pagination = usePagination(1);
  const { magasinFilter, membreFilter, dateStartFilter, dateEndFilter } =
    useFilters(() => pagination.changePage(1));

  const handleOpenDownload = (id: any) => {
    setActiveDownloadID(id);
    setHeureArrivee("");
  };

  const handleCloseDownload = () => {
    setActiveDownloadID(undefined);
    setHeureArrivee("");
    setError({ heureArrivee: "" });
  };

  const validateFields = () => {
    let isValid = true;

    if (!heureArrivee) {
      setError((prev) => ({ ...prev, heureArrivee: "L'heure est requise." }));
      isValid = false;
    } else {
      setError((prev) => ({ ...prev, heureArrivee: "" }));
    }
    return isValid;
  };

  const retardsQuery = useRetardsQuery({
    key: {
      p: pagination.page,
      magasin: Number(magasinFilter.value.id) || "",
      personel: Number(membreFilter.value.id) || "",
      date_start: serverDateFormat(dateStartFilter.value) || "",
      date_end: serverDateFormat(dateEndFilter.value) || "",
    },
  });

  function RetardsList() {
    if (retardsQuery.isLoading) {
      return <Loader />;
    }

    if (retardsQuery.isError) {
      return (
        <Alert severity="error" variant="standard" sx={{ mt: 8 }}>
          Une erreur est survenue
        </Alert>
      );
    }

    if (retardsQuery.data.count === 0) {
      return (
        <Alert severity="warning" variant="standard" sx={{ mt: 8 }}>
          La liste des retards est vide
        </Alert>
      );
    }

    const formatTime = (time: string | null) => {
      if (!time) return null;
      const [hour, minute] = time.split(":");
      return `${hour}:${minute}`;
    };

    const getOrderedTimes = (retard: any) => {
      const times = [
        formatTime(retard.pointage_matin_heure_debut),
        formatTime(retard.pointage_matin_heure_fin),
        formatTime(retard.pointage_apresmidi_heure_debut),
        formatTime(retard.pointage_apresmidi_heure_fin),
      ].filter((time) => time !== null);

      return times.sort();
    };

    const isPointageComplete = (retard: any) => {
      return (
        retard.pointage_matin_heure_debut &&
        retard.pointage_matin_heure_fin &&
        retard.pointage_apresmidi_heure_debut &&
        retard.pointage_apresmidi_heure_fin
      );
    };

    const handleSave = () => {
      if (validateFields()) {
        const selectedRetard = retardsQuery.data.results.find(
          (retard) => retard.id === activeDownloadID
        );

        if (!selectedRetard) {
          toast.error("Aucun employé trouvé pour ce retard.");
          return;
        }

        const today = new Date().toISOString().split("T")[0];
        const retardDate = new Date(selectedRetard.heure_arrivee)
          .toISOString()
          .split("T")[0];

        //   if (today !== retardDate) {
        //   toast.error(
        //     "Impossible de créer un pointage : La date d'aujourd'hui ne correspond pas à la date de retard."
        //   );
        //   handleCloseDownload();
        //   return;
        // }
        
        
        addPointage.mutate(
          {
            employe: selectedRetard.membre.id,
            checktime: heureArrivee,
            checkdate: retardDate,
          },
          {
            onSuccess: () => {
              retardsQuery.refetch();
              handleCloseDownload();
            },
            onError: (error) => {
              handleCloseDownload();
            },
          }
        );
      } else {
        toast.error("Veuillez remplir l'heure de pointage.");
      }
    };

    return (
      <>
        <TableContainer>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>Membre</TableCell>
                <TableCell>Date retard</TableCell>
                <TableCell>Heure arrivée</TableCell>
                <TableCell>Durée</TableCell>
                <TableCell>Commentaire</TableCell>
                <TableCell>Pointage</TableCell>
                {userInfoQuery?.data?.type_profile === "superuser" && (
                  <TableCell>Action</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {retardsQuery.data.results.map((retard) => (
                <TableRow key={retard.id}>
                  <TableCell>
                    <MembrePopup membreId={retard.membre.id}>
                      <Stack direction="row" alignItems="center">
                        <Avatar src={retard.membre.photo} sx={{ mr: 2 }} />
                        <Stack direction="column" alignItems="flex-start">
                          <Typography fontSize="1rem">
                            {retard.membre.first_name +
                              " " +
                              retard.membre.last_name}
                          </Typography>
                          <Stack direction="row" alignItems="center">
                            <Work
                              htmlColor="#666666"
                              sx={{ fontSize: "0.9rem", mr: 1 }}
                            />
                            <Typography fontSize="0.8rem" color="#666666">
                              {retard.membre.poste_nom || "-"}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </MembrePopup>
                  </TableCell>
                  <TableCell>
                    {standardDateFormat(retard.heure_arrivee)}
                  </TableCell>
                  <TableCell>
                    {standardTimeFormat(retard.heure_arrivee)}
                  </TableCell>
                  <TableCell>{retard.duree} min</TableCell>
                  <TableCell>{retard.commentaire}</TableCell>
                  <TableCell>
                    {getOrderedTimes(retard).map((time, index) => (
                      <Typography key={index} variant="body2">
                        {time}
                      </Typography>
                    ))}
                  </TableCell>

                  {userInfoQuery?.data?.type_profile === "superuser" && (
                    <TableCell>
                      {" "}
                      <Button
                        sx={{ height: 39 }}
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          handleOpenDownload(retard.id);
                        }}
                        disabled={isPointageComplete(retard)}
                        style={{
                          backgroundColor: isPointageComplete(retard)
                            ? "#f0f0f0"
                            : "",
                        }}
                      >
                        Ajouter
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <MainPagination
          page={pagination.page}
          numberOfPages={pagination.countPages(
            retardsQuery.data.count,
            retardsQuery.data.count_per_page
          )}
          changePage={pagination.changePage}
        />

        {/* Pop up Pointage */}
        <Dialog
          open={activeDownloadID !== undefined}
          onClose={handleCloseDownload}
          fullWidth
        >
          <DialogTitle sx={{ textAlign: "center" }}>
            Ajouter Pointage
          </DialogTitle>
          <DialogContent>
            <Stack spacing={3} mt={2}>
              <TextField
                label="Nom du membre"
                value={
                  retardsQuery.data.results.find(
                    (retard) => retard.id === activeDownloadID
                  )
                    ? `${
                        retardsQuery.data.results.find(
                          (retard) => retard.id === activeDownloadID
                        )?.membre.first_name
                      } ${
                        retardsQuery.data.results.find(
                          (retard) => retard.id === activeDownloadID
                        )?.membre.last_name
                      }`
                    : ""
                }
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                label="Heure"
                type="time"
                value={heureArrivee}
                onChange={(e) => setHeureArrivee(e.target.value)}
                fullWidth
                InputLabelProps={{ shrink: true }}
                error={Boolean(error.heureArrivee)}
                helperText={error.heureArrivee}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDownload} color="secondary">
              Annuler
            </Button>
            <Button variant="contained" color="primary" onClick={handleSave}>
              Enregistrer
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  return (
    <Box>
      <MFCard variant="outlined">
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Stack spacing={2} direction="row" m={6}>
            <MainFilter
              magasinFilter={magasinFilter}
              membreFilter={membreFilter}
              dateStartFilter={dateStartFilter}
              dateEndFilter={dateEndFilter}
            />
          </Stack>
        </Stack>
      </MFCard>

      {RetardsList()}
    </Box>
  );
}
