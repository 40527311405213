import { Work } from "@mui/icons-material";
import ArrowRight from "@mui/icons-material/ArrowRightAlt";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import MainFilter from "components/commun/MainFilter";
import MainPagination from "components/commun/MainPagination";
import Loader from "components/commun/ui/Loader";
import MFCard from "components/commun/ui/MFCard";
import MembrePopup from "components/personnels/components/MembrePopup";
import useAbsencesQuery from "hooks/queries/assiduite/useAbsencesQuery";
import useFilters from "hooks/useFilters";
import usePagination from "hooks/usePagination";
import {
  serverDateFormat,
  standardDateFormat,
  standardTimeFormat,
} from "utils/datetimeUtils";
import { useState } from "react";
import toast from "react-hot-toast";
import useAddPointageMutation from "hooks/queries/assiduite/useAddPointageMutation";
import useInfoProfileQuery from "hooks/queries/auth/useInfoProfileQuery"


export default function AbsencesDemandes() {
  const userInfoQuery = useInfoProfileQuery()
  const [OpenPointageID, setOpenPointageID] = useState(undefined);
  const [error, setError] = useState({ heureArrivee: "" });
  const [heureArrivee, setHeureArrivee] = useState("");

  const addPointage = useAddPointageMutation();

  const pagination = usePagination(1);
  const { magasinFilter, membreFilter, dateStartFilter, dateEndFilter } =
    useFilters(() => pagination.changePage(1));

  const handleOpenPointage = (id: any) => {
    setOpenPointageID(id);
    setHeureArrivee("");
  };

  const handleClosePointage = () => {
    setOpenPointageID(undefined);
    setHeureArrivee("");
    setError({ heureArrivee: "" });
  };

  const validateFields = () => {
    let isValid = true;

    if (!heureArrivee) {
      setError((prev) => ({ ...prev, heureArrivee: "L'heure est requise." }));
      isValid = false;
    } else {
      setError((prev) => ({ ...prev, heureArrivee: "" }));
    }
    return isValid;
  };

  const absencesQuery = useAbsencesQuery({
    key: {
      p: pagination.page,
      magasin: Number(magasinFilter.value.id) || "",
      personel: Number(membreFilter.value.id) || "",
      date_start: serverDateFormat(dateStartFilter.value) || "",
      date_end: serverDateFormat(dateEndFilter.value) || "",
      type: "pointage",
    },
  });

  function AbsencesList() {
    if (absencesQuery.isLoading) {
      return <Loader />;
    }

    if (absencesQuery.isError) {
      return (
        <Alert severity="error" variant="standard" sx={{ mt: 8 }}>
          Une erreur est survenue
        </Alert>
      );
    }

    if (absencesQuery.data.count === 0) {
      return (
        <Alert severity="warning" variant="standard" sx={{ mt: 8 }}>
          La liste des absences est vide
        </Alert>
      );
    }

    const formatTime = (time: string | null) => {
      if (!time) return null;
      const [hour, minute] = time.split(":");
      return `${hour}:${minute}`;
    };
  
    const getOrderedTimes = (absence: any) => {
      const times = [
        formatTime(absence.pointage_matin_heure_debut),
        formatTime(absence.pointage_matin_heure_fin),
        formatTime(absence.pointage_apresmidi_heure_debut),
        formatTime(absence.pointage_apresmidi_heure_fin),
      ].filter((time) => time !== null);
  
      return times.sort();
    };

    const isPointageComplete = (absence: any) => {
      return (
        absence.pointage_matin_heure_debut &&
        absence.pointage_matin_heure_fin &&
        absence.pointage_apresmidi_heure_debut &&
        absence.pointage_apresmidi_heure_fin
      );
    };

    const handleSave = () => {
      if (validateFields()) {
        const selectedAbsence = absencesQuery.data.results.find(
          (absence) => absence.id === OpenPointageID
        );
    
        if (!selectedAbsence) {
          toast.error("Aucun employé trouvé pour ce absence.");
          return;
        }

        const today = new Date().toISOString().split("T")[0];
        const absenceDate = new Date(selectedAbsence.date_demande)
          .toISOString()
          .split("T")[0];

        //   if (today !== absenceDate) {
        //   toast.error(
        //     "Impossible de créer un pointage : La date d'aujourd'hui ne correspond pas à la date d'absence."
        //   );
        //   handleClosePointage();
        //   return;
        // }
        
    
        addPointage.mutate(
          {
            employe: selectedAbsence.membre.id, 
            checktime: heureArrivee,
            checkdate: absenceDate,
            
          },
          {
            onSuccess: () => {
              absencesQuery.refetch();
              handleClosePointage();
            },
            onError: (error) => {
              handleClosePointage();
            },
          }
        );
      } else {
        toast.error("Veuillez remplir l'heure de pointage.");
      }
    };

    return (
      <>
        <TableContainer>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>Membre</TableCell>
                <TableCell>Date d'absence</TableCell>
                <TableCell>Periode</TableCell>
                <TableCell>Durée</TableCell>
                <TableCell>Pointage</TableCell>
                {userInfoQuery?.data?.type_profile === "superuser" && <TableCell>Action</TableCell>}              </TableRow>
            </TableHead>
            <TableBody>
              {absencesQuery.data.results.map((absence) => (
                <TableRow key={absence.id}>
                  <TableCell>
                    <MembrePopup membreId={absence.membre.id}>
                      <Stack direction="row" alignItems="center">
                        <Avatar src={absence.membre.photo} sx={{ mr: 2 }} />
                        <Stack direction="column" alignItems="flex-start">
                          <Typography fontSize="1rem">
                            {absence.membre.first_name +
                              " " +
                              absence.membre.last_name}
                          </Typography>
                          <Stack direction="row" alignItems="center">
                            <Work
                              htmlColor="#666666"
                              sx={{ fontSize: "0.9rem", mr: 1 }}
                            />
                            <Typography fontSize="0.8rem" color="#666666">
                              {absence.membre.poste_nom || "-"}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </MembrePopup>
                  </TableCell>
                  <TableCell>
                    {standardDateFormat(absence.date_demande)} |{" "}
                    {standardTimeFormat(absence.date_demande)}
                  </TableCell>
                  <TableCell>
                    <Stack spacing={2} direction="row" alignItems="center">
                      {standardDateFormat(absence.date_debut)}
                      <ArrowRight color="action" sx={{ px: 1 }} />
                      {absence.date_fin
                        ? standardDateFormat(absence.date_fin)
                        : "-"}
                    </Stack>
                  </TableCell>
                  <TableCell>
                    {absence.duree ? `${absence.duree} jour(s)` : "-"}
                  </TableCell>
                  <TableCell>
                    {getOrderedTimes(absence).map((time, index) => (
                      <Typography key={index} variant="body2">{time}</Typography>
                    ))}
                  </TableCell>
                  {userInfoQuery?.data?.type_profile === "superuser" && 
                  <TableCell>
                    {" "}
                    <Button
                      sx={{ height: 39 }}
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        handleOpenPointage(absence.id);
                      }}
                      disabled={isPointageComplete(absence)}
                      style={{
                        backgroundColor: isPointageComplete(absence)
                          ? "#f0f0f0"
                          : "", 
                      }}
                    >
                      Ajouter
                    </Button>
                  </TableCell>
                  }
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <MainPagination
          page={pagination.page}
          numberOfPages={pagination.countPages(
            absencesQuery.data.count,
            absencesQuery.data.count_per_page
          )}
          changePage={pagination.changePage}
        />

        <Dialog
          open={OpenPointageID !== undefined}
          onClose={handleClosePointage}
          fullWidth
        >
          <DialogTitle sx={{ textAlign: "center" }}>
            Ajouter Pointage
          </DialogTitle>
          <DialogContent>
            <Stack spacing={3} mt={2}>
              <TextField
                label="Nom du membre"
                value={
                  absencesQuery.data.results.find(
                    (absence) => absence.id === OpenPointageID
                  )
                    ? `${
                        absencesQuery.data.results.find(
                          (absence) => absence.id === OpenPointageID
                        )?.membre.first_name
                      } ${
                        absencesQuery.data.results.find(
                          (absence) => absence.id === OpenPointageID
                        )?.membre.last_name
                      }`
                    : ""
                }
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
               label="Heure"
               type="time"
               value={heureArrivee}
               onChange={(e) => setHeureArrivee(e.target.value)}
               fullWidth
               InputLabelProps={{ shrink: true }}
               error={Boolean(error.heureArrivee)}
               helperText={error.heureArrivee}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClosePointage} color="secondary">
              Annuler
            </Button>
            <Button variant="contained" color="primary" onClick={handleSave}>
              Enregistrer
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  return (
    <Box>
      <MFCard variant="outlined">
        <Stack spacing={2} direction="row" m={6}>
          <MainFilter
            magasinFilter={magasinFilter}
            membreFilter={membreFilter}
            dateStartFilter={dateStartFilter}
            dateEndFilter={dateEndFilter}
          />
        </Stack>
      </MFCard>

      {AbsencesList()}
    </Box>
  );
}
