export const KEY_TEST_TOKEN = "test-token"
export const KEY_INFO_PROFILE = "info-profile"
export const KEY_ACHATS = "achats"
export const KEY_CONGES = "conges"
export const KEY_DOCUMENTS = "documents"
export const KEY_ABSENCES = "absences"
export const KEY_RETARDS = "retards"
export const KEY_MAGASINS = "magasins"
export const KEY_MEMBRES = "membres"
export const KEY_MARQUES = "marques"
export const KEY_PLANNING = "planning"
export const KEY_PLANNING_DETAILS = "planning-details"
export const KEY_VILLES = "villes"
export const KEY_POSTES = "postes"
export const KEY_NOTIFICATIONS = "notifications"
export const KEY_TOKENS = "tokens"
export const KEY_NOW = "now"
export const KEY_POINTAGE = "pointage"
